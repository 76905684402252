<template>
    <el-table :data="tableData" stripe style="width: 100%" :height="tableMaxHeight" :show-summary="showSummary"
              :header-cell-style="{background:'#4194F2',color:'#fff',borderRight:'1px solid #4194F2'}"
              @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55" v-if="selection"></el-table-column>
        <slot></slot>
    </el-table>
</template>

<script>
    export default {
        name: "publicTable",
        data() {
            return {
                tableMaxHeight: 0,
                tableData: [],
                selection: false,
                multipleSelection: [],
                showSummary: false,
            }
        },
        props: ['publicTableOtherHeight', 'publicTableData', 'publicTableConfigure'],
        watch: {
            publicTableConfigure() {
                this.configureChange()
            },
            publicTableData() {
                this.publicTableDataChange()
            },
            publicTableOtherHeight() {
                this.calculateHeight()
            }
        },
        created() {
            this.calculateHeight()
            this.publicTableDataChange()
            this.configureChange()
        },
        methods: {
            configureChange() {
                if (this.publicTableConfigure) {
                    let publicTableConfigure = JSON.parse(JSON.stringify(this.publicTableConfigure))
                    for (let k in publicTableConfigure) {
                        this[k] = publicTableConfigure[k]
                    }
                }
            },
            handleSelectionChange(val) {
                if (this.selection) {
                    this.$emit('selAllDataFun', val)
                }
            },
            publicTableDataChange() {
                this.tableData = this.publicTableData;
                this.multipleSelection = [];
            },
            calculateHeight() {
                this.$nextTick(() => {
                    let domHeight = document.documentElement.clientHeight;
                    this.tableMaxHeight = domHeight - this.publicTableOtherHeight - 140;
                })
            }
        }
    }
</script>

<style scoped>

</style>
